<template>
  <Listbox
    v-model="selectedOption"
    as="div"
    class="flex w-full justify-center"
    :disabled="loading"
  >
    <ListboxLabel class="sr-only">Change setpoint preset</ListboxLabel>
    <div class="relative w-full">
      <ListboxButton
        class="flex w-full items-center justify-center gap-2 py-2 hover:bg-gray-50 active:bg-gray-100"
      >
        <span class="sr-only">Change setpoint preset</span>
        <p class="text-sm font-semibold text-gray-800">
          {{ selectedOption.name }}
        </p>
        <ChevronDownIcon class="h-5 w-5 text-gray-600" aria-hidden="true" />
      </ListboxButton>

      <transition
        leave-active-class="transition ease-in duration-100"
        leave-from-class="opacity-100"
        leave-to="opacity-0"
      >
        <ListboxOptions
          class="absolute right-0 z-10 mt-2 w-72 origin-top-right divide-y divide-gray-200 overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
        >
          <ListboxOption
            v-for="option in allOptions"
            :key="option.id || option.name"
            v-slot="{ active, selected }"
            as="template"
            :value="option"
          >
            <li
              :class="[
                active ? 'bg-blue-600 text-white' : 'text-gray-900',
                'cursor-default select-none p-4 text-sm',
              ]"
            >
              <div class="flex flex-col gap-2">
                <div class="flex justify-between">
                  <p
                    :class="
                      selected || option.id === 'create'
                        ? 'font-semibold'
                        : 'font-normal'
                    "
                  >
                    {{ option.name }}
                    <template v-if="option.setpointTempLow !== undefined">
                      ({{ option.setpointTempLow }}º -
                      {{ option.setpointTempHigh }}º)
                    </template>
                  </p>
                  <span
                    v-if="selected"
                    :class="active ? 'text-white' : 'text-blue-600'"
                  >
                    <CheckIcon class="h-5 w-5" aria-hidden="true" />
                  </span>
                  <span
                    v-else-if="option.id === 'create'"
                    :class="active ? 'text-white' : 'text-blue-600'"
                  >
                    <PlusIcon class="h-6 w-6" aria-hidden="true" />
                  </span>
                </div>
                <p
                  v-if="option.description"
                  :class="[active ? 'text-blue-200' : 'text-gray-500']"
                >
                  {{ option.description }}
                </p>
              </div>
            </li>
          </ListboxOption>
        </ListboxOptions>
      </transition>
    </div>
  </Listbox>

  <CreateSetpointPresetModal
    :is-open="showCreateModal"
    @close="showCreateModal = false"
    @create="createPreset"
  />
</template>

<script>
import {
  Listbox,
  ListboxButton,
  ListboxLabel,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/vue";
import { CheckIcon, ChevronDownIcon } from "@heroicons/vue/20/solid";
import { PlusIcon } from "@heroicons/vue/24/outline";
import CreateSetpointPresetModal from "./CreateSetpointPresetModal.vue";

export default {
  name: "SetpointPresetSelectMenu",
  components: {
    Listbox,
    ListboxButton,
    ListboxLabel,
    ListboxOption,
    ListboxOptions,
    CheckIcon,
    ChevronDownIcon,
    CreateSetpointPresetModal,
    PlusIcon,
  },
  props: {
    actuatorIndex: {
      type: Number,
      required: true,
    },
  },
  emits: ["loadingChanged", "setpointModeChanged"],
  data() {
    return {
      loading: false,
      showCreateModal: false,
    };
  },
  computed: {
    actuator() {
      return this.$store.state.venueActuators[this.actuatorIndex];
    },
    setpointPresets() {
      return this.$store.state.setpointPresets;
    },
    setpointPresetOptions() {
      const currentPresetUuid = this.actuator.currentSetpointPresetUuid;
      const currentPreset = this.setpointPresets[currentPresetUuid];

      let options = this.actuator.availableSetpointPresetUuids
        .filter((id) => id !== currentPresetUuid)
        .map((id) => this.setpointPresets[id])
        .sort((a, b) => a.name.localeCompare(b.name));

      if (currentPreset) {
        options.unshift(currentPreset);
      }

      return options;
    },
    allOptions() {
      return [
        {
          id: "manual",
          name: "Manual",
          description: "Selecciona los setpoints manualmente",
        },
        ...this.setpointPresetOptions,
        {
          id: "create",
          name: "Crear",
          description: "Crea un nuevo preset de setpoints",
        },
      ];
    },
    selectedOption: {
      get() {
        if (this.actuator.setpointMode === "manual") {
          return { id: "manual", name: "Manual" };
        }
        return (
          this.setpointPresets[this.actuator.currentSetpointPresetUuid] || {
            id: "manual",
            name: "Manual",
          }
        );
      },
      set(option) {
        if (option.id === "manual") {
          this.updateActuatorSettings({ setpointMode: "manual" });
        } else if (option.id === "create") {
          this.showCreateModal = true;
        } else {
          this.updateActuatorSettings({
            setpointMode: "preset",
            currentSetpointPresetUuid: option.id,
          });
        }
      },
    },
  },
  methods: {
    updateActuatorSettings(settings) {
      this.loading = true;
      this.$emit("loadingChanged", true);

      this.$store
        .dispatch("updateActuator", {
          id: this.actuator.id,
          data: settings,
        })
        .then(() => {
          this.loading = false;
          this.$emit("loadingChanged", false);
          if (settings.setpointMode) {
            this.$emit("setpointModeChanged", settings.setpointMode);
          }
        });
    },
    createPreset(preset) {
      this.loading = true;
      this.$emit("loadingChanged", true);

      this.$store
        .dispatch("createSetpointPreset", {
          id: this.actuator.id,
          data: preset,
        })
        .then(() => {
          this.showCreateModal = false;
        });
    },
  },
};
</script>
